////////////////////////////////
// Browser-related functionality
////////////////////////////////

(function(documentReady, container) {
	var wd = window.document,
		isReady = false,
		eventBound = false,
		readyList = [];

	function ready() {
		if (!isReady) {
			if (!wd.body) return setTimeout(ready, 13);
			isReady = true;

			while (listener = readyList.shift())
				listener();

			readyList = null;
		}
	}

	function DOMLoaded() {
		wd.removeEventListener("DOMContentLoaded", DOMLoaded, false);
		ready();
	}

	function bindReady() {
		if (eventBound) return;
		eventBound = true;
		if (wd.readyState === "complete") return ready();

		wd.addEventListener("DOMContentLoaded", DOMLoaded, false);
		window.addEventListener("load", ready, false);
	}

	container[documentReady] = function(fn) {
		bindReady();
		if (isReady)
			fn.call(wd);
		else
			readyList.push(fn);
	};

})('documentReady', qcodo);

qcodo.extend( qcodo, {
    loadJavaScriptFile: function(strScript, objCallback) {
        var el = document.createElement("script");
        el.setAttribute("src", strScript);
        qcodo.objForm.appendChild(el);

        el.onreadystatechange = objCallback;
        el.onload = objCallback;
    },

    safeActiveElement: function() {
        try { return document.activeElement; }
        catch (err) {}
    },

	realPropertyColor: function(el, property) {
		var transparent = 'rgba(0, 0, 0, 0)';
		var transparentIE11 = 'transparent';

		if (!el) return transparent;

		var value = getComputedStyle(el)[property];

		if (value === transparent || value === transparentIE11) {
			return this.realPropertyColor(el.parentElement, property);
		}

		// console.log(value);
		// console.log(value === transparent);
		// console.log(value === transparentIE11);
		// console.log(el);
		return value;
	},

    computedStyle: function(el) {
	    var computedStyle = getComputedStyle(el);
	    var toReturn = [];

	    for (prop in computedStyle) {
		    toReturn[prop] = computedStyle[prop];
	    }

    	toReturn.color = qcodo.realPropertyColor(el, 'color');
    	toReturn.backgroundColor = qcodo.realPropertyColor(el, 'backgroundColor');

    	return toReturn;
    },

    // CSS1Compat - standards mode (false), BackCompat - quirks mode (true)
    compatMode: (function() {
        return window.document.compatMode === "BackCompat";
    })()
});

qcodo.documentReady(function() {
	qcodo.boxModel = (function() {
		var boxModel, div = document.createElement("div");

		div.style.width = div.style.paddingLeft = "1px";
		document.body.appendChild(div);

		boxModel = div.offsetWidth === 2;
		document.body.removeChild(div).style.display = 'none';
		return boxModel;
	})();
});